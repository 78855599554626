.main {
  margin: 20px;
  min-height: 50vh;

  @media screen and (min-width: 1140px) {
    max-width: 1340px;
  }

  @media screen and (min-width: 1380px) {
    max-width: 1340px;
    margin: 0 auto;
    padding: 30px 0;
  }
}
