@use '@on3/styles/variables' as var;

.footer {
  background-color: #212121;
  padding: 20px;

  @media screen and (min-width: 961px) {
    display: none;
  }

  .container {
    max-width: 500px;
    margin: 0 auto;
  }

  .heading {
    color: #f8b217;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .flex {
    display: flex;
  }

  .garryimage {
    width: 100px;
    height: 116px;
    margin-left: 20px;
  }

  .body {
    color: #f8b217;
  }
}
