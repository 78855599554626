@use '@on3/styles/variables' as var;

.block {
  max-width: 700px;
  margin: 0 auto;
}

.loginForm {
  box-shadow: -3px 3px 40px rgb(0 0 0 / 8%);
}

.heading {
  font-size: 24px;
  margin-bottom: 40px;
  text-align: center;

  @media screen and (min-width: 961px) {
    font-size: 50px;
  }
}

.register {
  margin-bottom: 40px;
  text-align: center;
}

.loginItem {
  max-width: 400px;
  margin: 15px auto;
}

.btnGoogle {
  margin: 15px auto;
}

.boxContainer {
  margin-bottom: 35px;
}

.subText {
  font-family: var.$geist;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.65px;
  padding: 15px;
}

.headerText {
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  padding: 15px;
  min-width: fit-content;

  @media only screen and (min-width: 961px) {
    font-size: 60px;
    margin: 45px;
  }
}

.login {
  color: var.$orange;
  margin-left: 5px;
  font-weight: 700;
}
