@use '@on3/styles/variables' as var;

.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.central {
  flex: 1;

  // TODO: come back and adjust once all components are in
  padding: 0;

  &.alternateBackground {
    background-color: var.$focusBackground;
  }
}

.adhesion {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
  min-height: 50px;
  z-index: 10000;
  text-align: center;
  background-color: #222833;

  :global(#mobile_adhesion_top) {
    :global(#airFrame),
    :global(.simpli-vplacement-) {
      display: none !important;
    }
  }

  @media only screen and (min-width: 961px) {
    display: none;
  }

  div {
    min-height: 50px;
  }
}

.skipToContent {
  position: absolute;
  top: -1000px;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.pianoBottom {
  display: none;
  width: 100%;
  position: fixed;
  bottom: 50px;
  left: 0;
  z-index: 100;
  background-color: #1d252c;
  text-align: center;
  padding: var.$spacing-regular;

  @media only screen and (min-width: 961px) {
    bottom: 0;
  }
}

.blocker {
  display: none;
  visibility: visible;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(0 0 0 / 60%);
  height: 100vh;
  z-index: 99;
}
