.ad {
  display: none;

  @media only screen and (min-width: 961px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 970px;
    min-height: 250px;
    margin: 25px auto;
  }
}
