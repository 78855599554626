@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

$bp-sm: 575px;
$bp-lg: 1000px;

.block {
  background-color: var.$on3night;
  padding: 0 var.$spacing-regular;

  .inner {
    display: grid;
    gap: var.$spacing-regular;
    width: 100%;
    margin: 0 auto;
    max-width: var.$desktop-width;
    padding: 3.5rem 0;

    @media screen and (min-width: $bp-lg) {
      gap: var.$spacing-lg;
    }
  }

  .menu {
    @include mix.flex($justify: space-between, $align: flex-start, $wrap: wrap);

    margin-bottom: var.$spacing-lg;
    row-gap: var.$spacing-lg;

    .logo {
      width: 100%;

      @media screen and (min-width: $bp-lg) {
        width: auto;
      }

      svg {
        height: 2.75rem;
      }
    }

    .title {
      display: block;
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.25;
      text-transform: uppercase;
      color: var.$white;
      padding-bottom: var.$spacing-sm;
    }

    .links {
      @include mix.flex($gap: 1rem, $direction: column, $align: flex-start);

      width: 50%;

      @media screen and (min-width: $bp-sm) {
        width: calc(100% / 3);
      }

      @media screen and (min-width: $bp-lg) {
        width: auto;
      }

      a {
        color: var.$neutral;
        font-size: 0.75rem;
      }

      .social {
        @include mix.flex($gap: var.$spacing-sm);

        svg {
          height: 1em;
          width: 1em;
          fill: currentcolor;
        }
      }
    }

    .badges {
      display: grid;
      gap: var.$spacing-regular;
      text-align: right;
      width: 50%;

      @media screen and (min-width: $bp-sm) {
        text-align: center;
        width: 100%;
      }

      @media screen and (min-width: $bp-lg) {
        text-align: right;
        width: auto;
      }

      .downloads {
        @include mix.flex(
          $direction: column,
          $gap: var.$spacing-sm,
          $align: flex-end,
          $justify: center
        );

        @media screen and (min-width: $bp-sm) {
          flex-direction: row;
          align-items: center;
        }

        img {
          height: 2.5rem;
          width: auto;
        }
      }
    }
  }

  .copyright {
    color: var.$neutralGray;
    font-size: 0.75rem;
    line-height: 1.25;
    letter-spacing: 0.15px;
    text-align: center;
    margin-bottom: var.$spacing-lg;

    @media screen and (min-width: var.$sidebar-break) {
      margin-bottom: 0;
    }
  }
}
